<template>
  <div>
      <b-tabs type="is-toggle" v-model="activeSimName" @input="changeSim">
        <b-tab-item v-for="sim in simulators" :label="sim.name" :value="sim.name"></b-tab-item>
      </b-tabs>
      <div id="ruffle-box" style="margin-left: auto; margin-right: auto;"></div>

  </div>
</template>

<script>
import HomeWrapper from "@/components/HomeWrapper";

export default {
  name: "Simulators",
  components: {HomeWrapper},
  data() {
    return {
      activeSimName: 'VOR',
      simulators: [
        {
          name: 'VOR',
          filename: 'VOR_Sim3_0.swf'
        },
        {
          name: 'HSI',
          filename: 'VOR_Sim3_0.swf'
        },
        {
          name: 'RMI',
          filename: 'RMI_Sim3_0.swf',
        },
        {
          name: 'ADF',
          filename: 'ADF_Sim3_0.swf'
        },
        {
          name: 'Pitot Static System Simulator',
          filename: 'PitotSimulator.swf'
        },
        {
          name: 'Compact Instrument Simulator',
          filename: 'CmpctMultiInstTLZM.swf'
        }
      ],
    }
  },
  methods: {
    changeSim() {
      window.location.replace('/simulators/' + this.activeSimName);
    }
  },
  mounted() {
    this.activeSimName = this.$route.params.sim;
    const sim = this.simulators.filter(x => x.name === this.activeSimName)[0];
    window.RufflePlayer = window.RufflePlayer || {};
    const ruffle = window.RufflePlayer.newest();
    const player = ruffle.createPlayer();
    const container = document.getElementById("ruffle-box");
    container.appendChild(player);
    player.load(`/flash/${sim.filename}`);
    player.style.width = "950px";
    player.style.height = "800px";
  }
}
</script>

<style scoped>

</style>
