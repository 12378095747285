<template>
  <div>
      <div class="columns">
        <div class="column is-3 is-0-mobile"></div>
        <div class="column is-6">
          <div
              class="card is-shadowless is-bordered"
              style="border: 2px dashed rgba(0, 0, 0, 0.13); padding: 16px; margin-left: auto; margin-right: auto;"
          >
            <div class="columns">
              <div class="column">
                <b-field label="Imię">
                  <b-input v-model="user.first_name" required type="text" minlength="3"></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Nazwisko">
                  <b-input v-model="user.last_name" required type="text" minlength="3"></b-input>
                </b-field>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <b-field label="Email">
                  <b-input v-model="user.email" required type="text" minlength="3"></b-input>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Hasło">
                  <b-input v-model="user.password"  type="password" password-reveal minlength="3"></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <b-field label="PESEL">
                  <b-input v-model="user.pesel" required type="text" minlength="3"></b-input>
                </b-field>
              </div>
            </div>
            <button class="button is-success" @click="save">Zapisz</button>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import HomeWrapper from "@/components/HomeWrapper";

export default {
  name: "Profile",
  components: {HomeWrapper},
  data() {
    return {
      user: {},
    };
  },
  async mounted() {
    this.user = await this.$api.getProfile();
    this.user.password = '';
  },
  methods: {
    save() {
      this.$api.editProfile(this.user);
      this.$buefy.toast.open({
        duration: 1000,
        message: 'Zapisano!',
        position: 'is-top',
        type: 'is-success'
      });
    }
  }
}
</script>

<style scoped>

</style>
